import contributors from "./contributors";
import * as images from "assets/images/projects";

const featuredProjects: IProject[] = [
  {
    title: "W031224: EmonBio Web",
    clientType: "EmonWeb Agency VN",
    description: "A bio platform for sharing information about yourself. Create your own beautiful bio and share it with others.",
    image: "",
    url: "",
    github: "",
    technologies: ["React", "Next.js", "Shadcn", "TailwindCSS", "TypeScript", "Zod", "Lucide", "React Hook Form", "Husky", "ESLint", "Prettier"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "",
  },
  {
    title: "T231024: Nextjs Shadcn Starter Template",
    clientType: "EmonWeb Agency VN",
    description: "A starter template for next.js project with Shadcn. Contains base ui and useful components. Base features: Theme Mode, Form Validation, Multi-language Support, Search Functionality, Error Pages, SEO, etc.",
    image: images.T231024,
    url: "",
    github: "https://github.com/EmonWeb-Agency-VN/nextjs-shadcn-starter-template",
    technologies: ["React", "Next.js", "Shadcn", "TailwindCSS", "TypeScript", "Zod", "Lucide", "React Hook Form", "Husky", "ESLint", "Prettier"],
    contributors: [
      contributors["xoaicc"],
      contributors["minhkonzz"],
      contributors["HoangCongMin"],
    ],
    deliveryDate: "",
  },
  {
    title: "T241024: Microservices Starter Template",
    clientType: "EmonWeb Agency VN",
    description: "A starter template for microservices back-end system.",
    image: "",
    url: "",
    github: "https://github.com/EmonWeb-Agency-VN/microservices-starter-template",
    technologies: ["Node.js", "Nest.js", "MySQL", "TypeScript", "Java", "Spring Boot", "Go", "Gin", "C#", "DOTNET", "Docker"],
    contributors: [
      contributors["tranduchuy682"],
      contributors["xuanduz"],
      contributors["phivandong"],
      contributors["KhaiHust"],
      contributors["thuanlb"],
      contributors["xoaicc"],
    ],
    deliveryDate: "",
  },
  {
    title: "PublishHub Web",
    clientType: "BDT. Inc",
    description: "System for reporting and managing publishing information of publisher.",
    image: images.PublishHubWeb,
    url: "https://fph.xuatbanpham.vn/",
    github: "",
    technologies: ["React", "Joy UI", "TailwindCSS", "TypeScript", "Express.js", "MySQL"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "",
  },
  {
    title: "Karate HANU Web",
    clientType: "Karate Club",
    description: "Official web of Hanoi University's Karate Club.",
    image: images.KarateHanuWeb,
    url: "https://kh.xoaicc.id.vn/",
    github: "https://github.com/xoaicc/karate-hanu-club",
    technologies: ["React"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2023-09-10",
  },
  {
    title: "Shop Acc Game Web",
    clientType: "A Streamer",
    description: "Web app for buying acc game online, such as: Free Fire, PUBG Mobile, AOV. Security with JWT.",
    image: images.ShopAccGameWeb,
    url: "",
    github: "",
    technologies: ["Next.js", "TailwindCSS", "Flowbite", "TypeScript", "Express.js", "MySQL"],
    contributors: [
      contributors["xoaicc"],
      contributors["codengoo"],
      contributors["dothi2k1"],
      contributors["chinxh-work"],
    ],
    deliveryDate: "2023-09-10",
  },
  {
    title: "Game Team Finder Web",
    clientType: "An Influencer",
    description: "Web app for find game partners like Lita, Playduo, but more modern than. Security with JWT.",
    image: images.GameTeamFinderWeb,
    url: "",
    github: "",
    technologies: ["Next.js", "TailwindCSS", "TypeScript", "C#", "ASP.NET", "PostgreSQL"],
    contributors: [
      contributors["xoaicc"],
      contributors["codengoo"],
      contributors["Thaife"],
      contributors["dothi2k1"],
      contributors["chinxh-work"],
    ],
    deliveryDate: "",
  },
  {
    title: "EmonWeb Agency Web",
    clientType: "EmonWeb Agency VN",
    description: "Official web of EmonWeb Agency VN.",
    image: images.EmonWebAgencyWeb,
    url: "https://emonweb.biz/",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-09-10",
  },
  {
    title: "CDNN Admission Landing",
    clientType: "Hanoi College of Foreign Languages",
    description: "Landing page for Admission of Hanoi College of Foreign Languages.",
    image: images.CdnnAdmissionLanding,
    url: "https://ts.hcfl.edu.vn",
    github: "",
    technologies: ["ladi.page"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-15",
  },
  {
    title: "CDNN official Web",
    clientType: "Hanoi Foreign Language College",
    description: "Official web of Hanoi Foreign Language College.",
    image: images.CdnnOfficialWeb,
    url: "https://cdnn.edu.vn/",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-10",
  },
  {
    title: "TVUni Training Web",
    clientType: "Trung Vuong University",
    description: "Web for online training of Trung Vuong University.",
    image: images.TVUniTrainingWeb,
    url: "https://web.tvuni.edu.vn/",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-08",
  },
  {
    title: "KTT-TVUni Web",
    clientType: "Faculty of Economics, Trung Vuong University",
    description: "Official web of Faculty of Economics, Trung Vuong University.",
    image: images.KttTvuniWeb,
    url: "https://khoakinhte.tv-uni.edu.vn/",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-01",
  },
  {
    title: "KSDH-TVUni Web",
    clientType: "Faculty of Graduation, Trung Vuong University",
    description: "Official web of Faculty of Graduation, Trung Vuong University.",
    image: "",
    url: "https://khoasaudaihoc.tvuni.edu.vn/",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-06",
  },
  {
    title: "KL-TVUni official Web",
    clientType: "Faculty of Law, Trung Vuong University",
    description: "Official web of Faculty of Law, Trung Vuong University.",
    image: "",
    url: "https://khoaluat.tvuni.edu.vn/",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-05",
  },
  {
    title: "Curtain Store Web",
    clientType: "Store owner",
    description: "A web for online curtain store of my client's store owner.",
    image: "",
    url: "",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2021-09-27",
  },
  {
    title: "Real Estate Web",
    clientType: "Real Estate company",
    description: "A web for Real Estate of my client's Real Estate company.",
    image: "",
    url: "",
    github: "",
    technologies: ["WordPress", "Elementor"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2022-10-10",
  },
  {
    title: "XoaiCV 2 Web",
    clientType: "Personal",
    description: "A web for introducing myself, but version 2.",
    image: images.XoaiCV2Web,
    url: "https://cv.xoaicc.id.vn",
    github: "https://github.com/xoaicc/xoaicv-2",
    technologies: ["HTML", "CSS", "JS"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-04-10",
  },
  {
    title: "XoaiCV Web",
    clientType: "Personal",
    description: "A web for introducing myself.",
    image: images.XoaiCVWeb,
    url: "https://xoaicc.github.io/xoaicv/",
    github: "https://github.com/xoaicc/xoaicv",
    technologies: ["HTML", "CSS", "JS"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2023-10-03",
  },
];

export default featuredProjects;