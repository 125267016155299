import {
  RocketIcon,
  TargetIcon,
  CompassIcon,
  FolderGitIcon,
} from "lucide-react";

import CareerBackground from "assets/images/career-bg.webp";
import ImageBackground from "components/ImageBackground";
import Breadcrumb from "components/Breadcrumb";
import { NormalCard } from "components/Cards";
import { NormalBadge } from "components/Badges";
import Section from "components/Section";
import Paragraph from "components/Paragraph";

import { coreValues } from "./data/coreValues";
import { careerDreams } from "./data/careerDreams";
import { projectIdeas, tags } from "./data/projectIdeas";

export default function CareerPage() {
  return (
    <>
      <ImageBackground bgColor="blue" imgSrc={CareerBackground} />
      <Breadcrumb currentRoute={{ name: "My Career", route: "/career" }} />

      {/* Declaration Section */}
      <div className="h-dvh flex justify-center items-center">
        <Paragraph className="max-w-3xl text-center">
          "I don&#39;t need to have great moments, but I definitely won&#39;t
          let my story go unmentioned. I don&#39;t want to waste a day anymore.
          Only continuous progress can make me sleep well."
        </Paragraph>
      </div>

      <div className="space-y-64">
        {/* Mission and Vision Section */}
        <Section title="A telescope into the future">
          <div className="grid md:grid-cols-2 gap-8">
            <NormalCard className="bg-gradient-to-br from-purple-500 to-indigo-500 hover:scale-105 transition-transform duration-300">
              <NormalCard.Header className="text-white">
                <TargetIcon className="mr-2 h-6 w-6" />
                <NormalCard.Title>Destination</NormalCard.Title>
              </NormalCard.Header>
              <NormalCard.Content>
                <p className="text-lg text-white">
                  My life is closely linked to the community, I not only want to
                  build a platform like WeChat, inheriting the will of
                  predecessors such as Zing Me, Lotus or Gapo, but also want to
                  build the largest business network. I believe that Vietnam
                  will rise even stronger.
                </p>
              </NormalCard.Content>
            </NormalCard>
            <NormalCard className="bg-gradient-to-br from-pink-500 to-red-500 hover:scale-105 transition-transform duration-300">
              <NormalCard.Header className="text-white">
                <CompassIcon className="mr-2 h-6 w-6" />
                <NormalCard.Title className="flex items-center text-2xl">
                  Compass
                </NormalCard.Title>
              </NormalCard.Header>
              <NormalCard.Content>
                <p className="text-lg text-white">
                  What always motivates me to move forward is the future of the
                  country and people I love becoming a strong testament to Uncle
                  Ho&#39;s wishes. In that journey, I do not want to lack my
                  contribution and that of people like me.
                </p>
              </NormalCard.Content>
            </NormalCard>
          </div>
        </Section>

        {/* Core Values Section */}
        <Section title="What makes me?">
          <div className="grid md:grid-cols-3 gap-8">
            {coreValues.map((value, index) => (
              <NormalCard
                key={index}
                className="hover:shadow-2xl transition-shadow duration-300"
              >
                <NormalCard.Header>
                  <value.icon className="mr-2 h-5 w-5 text-purple-600" />
                  <NormalCard.Title className="flex items-center text-xl">
                    {value.title}
                  </NormalCard.Title>
                </NormalCard.Header>
                <NormalCard.Content>
                  <p className="text-gray-700">{value.description}</p>
                </NormalCard.Content>
              </NormalCard>
            ))}
          </div>
        </Section>

        {/* Career Dreams Section */}
        <Section title="This is whole my life">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {careerDreams.map((job, index) => (
              <NormalCard
                key={index}
                className="transition-transform duration-300 transform hover:-translate-y-1"
              >
                <NormalCard.Header>
                  <job.icon className="mr-2 h-5 w-5 text-pink-600" />
                  <NormalCard.Title className="flex items-center text-xl">
                    {job.title}
                  </NormalCard.Title>
                </NormalCard.Header>
                <NormalCard.Content>
                  <p className="text-gray-700">{job.description}</p>
                </NormalCard.Content>
              </NormalCard>
            ))}
          </div>
        </Section>

        {/* Future Plans Section */}
        <Section title="What happen Next?" moreClass="space-y-8">
          <NormalCard className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 hover:scale-105 transition-transform duration-300">
            <NormalCard.Header className="text-white">
              <RocketIcon className="mr-2 h-6 w-6" />
              <NormalCard.Title>Startup: EmonWeb Agency VN</NormalCard.Title>
            </NormalCard.Header>
            <NormalCard.Content>
              <p className="text-lg mb-4 text-white">
                EmonWeb is a gathering place for people who love the web. Where
                we develop web in a new, impressive and unique style that has
                never been seen before, combining the art of storytelling and
                skillful techniques. We don&#39;t just outsource, we turn great
                ideas for community into reality if you become an investor. Emon
                love Web, Emon Serve Web.
              </p>
              <NormalBadge className="bg-white text-orange-600">
                Coming Soon
              </NormalBadge>
            </NormalCard.Content>
          </NormalCard>

          <h3 className="text-2xl text-center font-semibold text-gray-800 mt-8 mb-4">
            Upcoming Ideas
          </h3>
          <div className="grid md:grid-cols-2 gap-6">
            {projectIdeas.map((idea, index) => (
              <NormalCard
                key={index}
                className="hover:scale-105 transition-transform duration-300"
              >
                <NormalCard.Header>
                  <span className="mr-2 flex items-center text-blue-500 text-2xl space-x-2">
                    <FolderGitIcon />
                    <b>Project:</b>
                  </span>
                  <NormalCard.Title>{idea.title}</NormalCard.Title>
                </NormalCard.Header>
                <NormalCard.Content>
                  <p className="text-gray-700 mb-4">{idea.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {idea.tags.map((tag, index) => (
                      <NormalBadge
                        key={index}
                        className={tags[tag] + " text-white"}
                      >
                        {tag}
                      </NormalBadge>
                    ))}
                  </div>
                </NormalCard.Content>
              </NormalCard>
            ))}
          </div>
        </Section>

        {/* Call to Action */}
        <Section title="Are You interested?">
          <NormalCard className="overflow-hidden">
            <NormalCard.Content className="i-twoColumns !p-0">
              <div className="px-4 py-8 text-center">
                <p className="text-xl text-gray-700 mb-8 max-w-2xl mx-auto">
                  Are you an explorer? Don&#39;t hesitate to invest in projects
                  and people with potential!
                </p>
                <button className="py-2 px-4 i-border rounded-lg bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:from-purple-700 hover:to-pink-700 transition-all duration-300 transform hover:-translate-y-1 capitalize">
                  Book a coffee night
                </button>
              </div>
              <div className="px-4 py-8 text-center bg-gradient-to-r from-purple-600 to-pink-600">
                <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
                  Are you a lone sailor? Join me in building a great boat and
                  sailing out to sea together!
                </p>
                <a href="https://emonweb.biz/recruitment" className="none">
                  <button className="py-2 px-4 i-border rounded-lg bg-gray-200 text-gray-900 transition-transform duration-300 transform hover:-translate-y-1 capitalize">
                    Can we are a team
                  </button>
                </a>
              </div>
            </NormalCard.Content>
          </NormalCard>
        </Section>
      </div>
    </>
  );
}
