import { useModal } from "contexts";
import { Img } from "react-image";
import truncateText from "utils/truncateText";

interface IVerticalImageCardProps {
  projectData: IProject;
}

/**
 * A vertical image card component. This component takes in a
 * projectData prop which is an object with title, description, and
 * image properties. It renders an image with the title and a
 * truncated description below it. On click, it opens a modal with
 * more details about the project. It's designed to be used in a
 * grid of projects.
 * @param {IVerticalImageCardProps} props - The props for this component
 * @returns {JSX.Element} - The JSX element representing the vertical image card
 */
export default function VerticalImageCard({
  projectData,
}: IVerticalImageCardProps): JSX.Element {
  const { setModalData, setIsVisible } = useModal();

  return (
    <div className="max-w-sm i-glassmorphism rounded-lg i-border shadow-lg">
      <div className="h-60 rounded-t-lg overflow-hidden">
        <Img
          className="h-full object-cover hover:animate-zoomIn transition-transform duration-300"
          src={projectData.image || "https://placehold.co/383x240.png"}
          alt={projectData.title + " image"}
          loading="lazy"
          loader={<div className="w-full h-full bg-gray-200 animate-pulse" />}
        />
      </div>
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {projectData.title}
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {truncateText(projectData.description || "", 18)}
        </p>
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => {
            setModalData(projectData);
            setIsVisible(true);
          }}
        >
          View Details
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
