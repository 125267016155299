import { Img } from "react-image";

interface IGridGalleryImage {
  imgSrc: string;
  alt: string;
}

interface IGridGalleryProps {
  images: IGridGalleryImage[];
  moreClass?: string;
}

/**
 * A component to render a grid of images. The images are displayed in a
 * responsive grid that can be styled with additional classes. The grid
 * is made up of a container div with a class of "grid gap-4" with each
 * image inside a div with the class "h-full w-full p-2 sm:p-6 rounded-lg
 * object-contain i-glassmorphism i-border". The images are rendered with
 * the "img" tag and are given a key based on their index. The "src" and
 * "alt" props are set to the "imgSrc" and "alt" properties of the image
 * object respectively.
 *
 * @param {IGridGalleryProps} props - The props for the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 * @example
 * <GridGallery
 *   images={[
 *     { imgSrc: "image1.jpg", alt: "Image 1" },
 *     { imgSrc: "image2.jpg", alt: "Image 2" },
 *     { imgSrc: "image3.jpg", alt: "Image 3" },
 *   ]}
 * />
 */

export default function GridGallery({
  images,
  moreClass,
}: IGridGalleryProps): JSX.Element {
  return (
    <div className={`grid gap-4 ${moreClass}`}>
      {images.map((image, index) => (
        <Img
          key={index}
          className="h-full w-full p-2 sm:p-6 rounded-lg object-contain i-glassmorphism i-border"
          src={image.imgSrc}
          alt={image.alt}
          loading="lazy"
          loader={<div className="w-full h-full bg-gray-200 animate-pulse" />}
        />
      ))}
    </div>
  );
}
